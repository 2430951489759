export default {
  primary: {
    color: 'light',
    border: '2px solid',
    borderRadius: '0px',
    padding: '1rem 3rem',
    borderColor: 'rgba(14,38,64,1)',
    fontWeight: '500',
    transition: 'all ease-in-out 0.6s',
    backgroundColor: 'rgba(14,38,64,1)',
    clipPath: 'polygon(5% 0,95% 0,100% 15%,100% 85%,95% 100%,5% 100%,0 85%,0 15%)',
    ':hover': {
      color: 'rgba(14,38,64,1)',
      backgroundColor: 'transparent',
    },
  },
  secondary: {
    backgroundColor: 'transparent',
    padding: '0.5rem',
    color: 'primary',
    border: 'none',
    borderBottom: 'solid 2px',
    borderColor: 'primary',
    fontWeight: 'bold',
    display: 'flex',
    ':hover': {
      opacity: '0.4',
      backgroundColor: 'transparent',
      color: 'grey',
    },
  },
}
