export default {
  // ? ===========================
  // ? =====   Reuseables   ======
  // ? ===========================

  title: {
    fontSize: ['2rem', '2.25rem', '2.5rem', '2.5rem'],
    textTransform: 'uppercase',
    fontWeight: '300',
    color: 'text',
    textAlign: 'left',
    width: '100%',
    borderBottom: '1px solid black',
    padding: '1rem 0rem',
  },

  subtitle: {
    fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem'],
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: 'secondary',
    border: 'none',
    textAlign: 'left',
    width: '100%',
    marginBottom: '1rem',
  },
  text: {
    width: '100%',
    textAlign: 'left',
  },

  sideBySide1: {
    minHeight: ['', '', '', '75vh'],
    margin: '1rem 1rem 0rem',
    width: 'calc(100% - 2rem)',

    '.content': {
      alignItems: 'flex-start',
      textAlign: 'left',
    },

    '.title': {
      variant: 'customVariants.title',

      order: '1',
    },

    '.subtitle': {
      variant: 'customVariants.subtitle',
      order: '2',
    },

    '.text': {
      order: '3',
      opacity: '0.8',
      textAlign: 'left',

      p: {
        variant: 'customVariants.text',
        lineHeight: ['', '', '1.75'],
      },
    },

    '.image': {
      height: '100%',
    },
    a: {
      '.ctaButton': {
        variant: 'buttons.primary',
      },
      order: '4',
      margin: '0px auto 0px 0px',
    },
  },

  // ? ===========================
  // ? =======   Layout   ========
  // ? ===========================

  navigation: {
    '.container': {
      padding: ['0.5rem', '0.5rem', '0.5rem', '1rem'],
      backgroundColor: 'white',
      borderBottom: 'solid 1px black',
    },
    '.containerScrolled': {
      // backgroundColor: 'dark',
      // color: 'light',
    },
    '.logo': {
      img: {
        filter: 'unset',
        filter: 'unset',
        maxHeight: '40px',
      },
    },
    '.logoScrolled': {
      img: {
        filter: 'unset',
        maxHeight: '40px',
      },
    },
    '.navMenuLogo': {
      img: {
        filter: 'unset !important',
      },
    },
    '.smallNavMenu': {
      '.navItem': {
        color: 'dark',
        margin: ['', '', '', '0.75rem', '1rem'],
        a: {
          fontSize: '1rem',
          ':hover': {
            color: '#4aa6db',
            textDecoration: 'underline',
          },
        },
      },
    },
    '.hamburger': {
      backgroundColor: 'rgb(255 255 255 / 42%)',
    },
    '.navMenuOpen': {
      alignItems: ['flex-end'],
      padding: ['2rem', '', '', '4rem'],
      width: ['85%', '', '', '35%'],
      left: '0rem',
      right: 'unset',
      borderRight: '2px solid #a9a4a4',
      '.address': { display: 'none !important' },
      '.navMenuLogo': {
        // position: ['', '', '', 'absolute'],
        // width: ['', '', '', '35%'],
        left: '1rem',
        img: {
          maxHeight: ['40px', '', '', '60px'],
          maxWidth: ['', '', '', 'unset'],
          marginBottom: '1rem',
          filter: 'brightness(1) invert(1)',

          // width: ['', '', '', ''],
        },
        transition: 'all ease-in-out 5s',
      },
      '.navItemDropdown': {
        textAlign: 'right',
        '.navItemDropdownMenu': {
          background: 'transparent',
          position: ['static', 'static', 'static', 'static'],
          a: {
            fontSize: '0.9rem',
          },
        },
      },

      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto'],
      },
      '.phoneSocialContainer': {
        flexDirection: ['column'],
        alignItems: ['flex-end'],
        justifyContent: ['flex-end'],
        textAlign: ['right'],
        a: {
          textAlign: ['right'],
          justifyContent: ['flex-end'],
          fontSize: '1rem',
        },
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
        fontFamily: 'heading',
        textTransform: 'uppercase',
      },

      '.socialIconsContainer': {
        // flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right',
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
        },
      },
    },

    '.navItemDropdown': {
      '.navItemDropdownMenu': {
        background: 'white',
        width: 'fit-content',
        a: {
          fontSize: '0.9rem',
        },
      },
    },
  },

  footer: {
    backgroundColor: 'white',
    borderTop: '2px solid grey',
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'black',
    },
    '.logo': {
      img: {
        maxHeight: '60px',
        filter: 'unset',
      },
    },
    '.multiButtonContainer': {
      border: 'none',
      a: {
        border: 'none!important',
        padding: ['0.5rem', '0.5rem', '0.5rem', '0.5rem'],
        ':hover': {
          opacity: '0.5',
          background: 'transparent',
        },
      },
    },
    '.socialLink': {
      svg: {
        path: {
          fill: 'black',
        },
      },
    },
    '.socialContainer a': {
      width: '100%',
    },
  },

  // ? ===========================
  // ? =======   Homepage   ========
  // ? ===========================
  loadingPage: {
    backgroundColor: 'white',
    '.logo': {
      // filter: 'brightness(1) invert(1)',
    },
  },

  homepageHero: {
    '.hero_content_container': {
      width: ['', '', '', '30%'],
    },

    '.title': {
      variant: 'customVariants.title',
      borderBottom: '1px solid black',
      textAlign: 'center',
    },

    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'center',
    },

    '.text': {
      p: {
        variant: 'customVariants.text',
        fontSize: ['1.25rem'],
      },
    },

    '.logoHero': {
      filter: 'unset',
      display: ['none', '', '', 'block'],
    },

    '.slick-slider': {
      width: ['100%', '', '', '70%'],
      clipPath: 'polygon(0 0, 100% 0, 100% 85%, 85% 100%, 0 100%, 0% 50%)',
    },

    '.slick-dots': {
      display: 'none !important',
    },

    '.slick-prev, .slick-next': {
      color: 'white',
      bottom: '0rem',
      backgroundColor: 'black',
      height: '30px',
      top: 'unset',
    },
    'svg.slick-prev': {
      left: '1rem',
      padding: '0.25em',
      height: '35px',
      width: '35px',
      color: 'white',
    },
    'svg.slick-next': {
      left: '4rem',
      padding: '0.25em',
      height: '35px',
      width: '35px',
      color: 'white',
    },
  },

  homepageHeroShout: {},

  homepageBoxes: {
    padding: ['2rem 1rem', '', '3rem 1rem', '6rem 1rem'],
    '.box': {
      alignItems: 'flex-start',
      textAlign: 'left',
      flexGrow: '1',
      padding: ['2rem 2rem', '2rem 3rem', '', '2rem 4rem'],
      maxWidth: '350px',
    },
    'a:nth-of-type(1)': {
      backgroundColor: 'black',
      borderRadius: '20px',
      color: 'white',
      '.text': {
        color: 'white',
      },
      '.image': {
        backgroundColor: 'white',
        boxShadow: '10px 10px 20px #3c5761',
      },
    },
    '.title': {
      fontSize: '2rem',
      marginBottom: '2rem',
    },
    '.image': {
      maxHeight: '60px',
      height: '120px',
      objectFit: 'contain',
      marginBottom: '2rem',
      width: 'fit-content',
      padding: '1rem',
      backgroundColor: 'white',
      borderRadius: '10px',
      boxShadow: '8px 8px 20px #dad1d1',
    },
    textAlign: 'center',

    '.text': {
      opacity: '0.7',
      fontSize: '0.9rem',
      lineHeight: '1.75',
    },
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
  },

  homepagePartners: {
    variant: 'customVariants.sideBySide1',
    '.content': {
      order: ['', '', '2'],
    },
  },

  homepageIndustries: {
    variant: 'customVariants.sideBySide1',
  },
  homepagePortfolioIntro: {
    backgroundImage: 'none',
    padding: ['4rem 2rem 0rem', '', '4rem 3rem 0rem'],
    '.section': {
      maxWidth: 'unset',
      margin: '0rem',
    },
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '1px solid black',
      padding: '1rem 0rem',
    },

    '.subtitle': {
      variant: 'customVariants.subtitle',
    },
    '.text': {
      opacity: '0.7',
      textAlign: 'left',
    },
  },
  homepagePortfolio: {
    padding: '0rem 1rem 2rem',
    '.box': {
      width: ['100%', 'calc(50% - 1rem)'],
      position: 'relative',
      padding: '0rem',
      margin: ['0.5rem 0rem', '0.5rem'],
      overflow: 'hidden',
      ':hover img': {
        transform: 'scale(1.2)',
      },
    },
    '.image': {
      height: '100%',
      margin: '0rem',
      transition: 'all ease-in-out 1s',
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['1.1rem', '1.2rem', '1.2rem', '1.5rem'],
      position: 'absolute',
      color: 'white',
      bottom: '2rem',
      right: '1rem',
      padding: '0rem',
      width: 'fit-content',
      borderColor: 'white',
    },

    '.subtitle': {
      variant: 'customVariants.subtitle',
    },
    '.text': {
      variant: 'customVariants.text',
      position: 'absolute',
      color: 'white',
      bottom: '0.5rem',
      right: '0.5rem',
      width: 'fit-content',
    },
  },

  homepageWork: {
    variant: 'customVariants.sideBySide1',
  },

  homepageTestimonials: {
    padding: ['2rem 1rem', '', '4rem 1rem'],
    maxWidth: 'unset',
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      border: 'none',
      textAlign: 'center',
      margin: '0 auto 1rem',
    },

    '.menuSectionDescription': {
      variant: 'customVariants.text',
      fontSize: '1rem',
    },

    '.menuItemNameContainer': {
      variant: 'customVariants.subtitle',
      order: '4',
      margin: '1rem auto 0rem',
      textAlign: 'center',
    },

    '.menuItemName': {
      fontSize: ['1rem', '1rem', '1rem', '1rem'],
    },

    '.menuItemDescription': {
      variant: 'customVariants.text',
      color: 'primary',
      fontSize: '1rem',
      textAlign: 'center',
      lineHeight: '1.5',
      width: '100%',
      padding: ['0rem', '', '0rem 1rem'],
      margin: '0 auto 1rem',
    },

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto',
    },
  },

  homepageContactUs: {
    variant: 'customVariants.sideBySide1',
    '.section': {
      alignItems: 'flex-start',
      alignItems: 'flex-start',
      margin: 'auto auto auto 8%',
      height: 'fit-content',
      backgroundColor: '#ffffffdb',
      padding: '2rem',
      '.title': {
        // color: 'white',
      },
    },
  },

  // ? ===========================
  // ? =====   About Page   ======
  // ? ===========================

  aboutSection: {
    '.title': {
      fontSize: '3.5rem',
      lineHeight: '1',
      color: 'primary',
    },
  },

  // ? ===========================
  // ? =======   Services   =======
  // ? ===========================

  services: {
    // '.menuItemContainerImgActive': {
    //   width: ['100%', '', 'calc(50% - 1rem)'],
    // },
    // '.menuItemContentContainer': {
    //   position: 'absolute',
    // },
  },

  // ? ===========================
  // ? =====   Gallery Page   ====
  // ? ===========================

  gallery: {
    padding: '0rem',
    '.albumsCTA': {
      width: [
        '100%',
        'calc(50% - 2rem)',
        'calc(50% - 2rem)',
        'calc(50% - 3rem)',
      ],
      height: '400px',
      color: 'white',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },

    '.albumTitle': {
      variant: 'buttons.primary',
      backgroundColor: 'transparent',
      color: 'white',
      borderColor: 'white',
      fontSize: '2rem',
      textTransform: 'uppercase',
    },

    '.backButton': {
      marginTop: '1rem',
    },
  },
  // ? ===========================
  // ? =====   Contact Page   ====
  // ? ===========================

  contactForm: {
    padding: '4rem 1rem',
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1.25rem',
      opacity: '0.8',
    },
    '.text': {
      fontStyle: 'italic',
      marginBottom: '3rem',
    },
    '.address': { display: 'none !important' },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid black',
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid black',
      },
      '.submitBtn': {
        borderRadius: '0px',
      },
    },
  },

  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase',
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0',
    },
  },

  // // ? ===========================
  // // ? ====  Industries Menu  ====
  // // ? ===========================

  // industriesIntro: {
  //   backgroundImage: 'unset',
  //   padding: '5rem 1rem 2rem',
  //   '.title': {
  //     variant: 'customVariants.title',
  //     textAlign: 'center',
  //   },
  //   '.text': {
  //     variant: 'customVariants.text',
  //     textAlign: 'center',
  //   },
  // },
  // industriesArchitect: {
  //   variant: 'customVariants.sideBySide1',
  //   '.content': {
  //     order: ['', '', '2'],
  //   },
  // },
  // industriesDesigners: {
  //   variant: 'customVariants.sideBySide1',
  // },
  // industriesContractors: {
  //   variant: 'customVariants.sideBySide1',
  //   '.content': {
  //     order: ['', '', '2'],
  //   },
  // },
  // industriesEngineers: {
  //   variant: 'customVariants.sideBySide1',
  // },

  // PortfolioIntroCT: {
  //   backgroundImage: 'none',
  // },

  // PortfolioIntroNY: {
  //   backgroundImage: 'none',
  // },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {},

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {},
}
