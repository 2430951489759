const variantName = 'menuV3a';

export default {
  [variantName]: {
    container: {
      // ?============================
      // ?======  Containers =========
      // ?============================
      padding: ['1rem 0rem', '1rem 0rem'],
      justifyContent: 'center',
      width: '100%',
      // maxWidth: '1200px',
      margin: '0 auto',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================
    backToMenuBtn: {
      backgroundColor: 'primary',
      border: 'none',
      margin: '0.5rem',
      cursor: 'pointer',
      justifyContent: 'center',
      paddingBottom: 'calc(0.5rem - 1px)',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingTop: 'calc(0.5rem - 1px)',
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },

    // ?============================
    // ?========  Images  ==========
    // ?============================

    imageFill: {
      paddingBottom: '100%',
    },

    // ?================================
    // ?===========  TABS  =============
    // ?================================

    tabsRow: {
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'scroll',
      padding: '20px 0',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      justifyContent: ['', '', '', 'center'],
      '.sectionTab': {
        margin: '10px',
      },
    },

    // ?================================
    // ?=========  ALL IN  =============
    // ?================================

    allInContainer: {},
    menuContainer: { paddingBottom: '2rem' },

    // ?================================
    // ?=========  CELLS  ==============
    // ?================================

    cellContainer: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },

    sectionCell: {
      cursor: 'pointer',
      width: [
        '100%',
        'calc(50% - (0.5rem * 2))',
        'calc(33.333% - (0.5rem * 2))',
        'calc(33% - (0.5rem * 2))',
        'calc(25% - (0.5rem * 2))',
      ],
      backgroundColor: 'secondary',
      borderRadius: '10px',
      margin: ['1rem 0', '0.5rem'],
    },

    menuCell: {
      flexWrap: 'wrap',
      border: 'solid 1px black',
      borderRadius: '10px',
      height: '100%',
      backgroundColor: 'background',
    },
    cellImageContainer: {
      position: 'relative',
      width: '100%',
      height: 'unset',
    },
    cellImage: {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px 10px 0 0',
    },
    cellName: {
      margin: '1rem',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },

    // ?================================
    // ?========  Menu Sections ========
    // ?================================

    menuSectionTitle: {
      fontSize: '2rem',
      textAlign: 'center',
      margin: '2rem',
      display: 'none',
    },
    menuSectionDescription: {
      textAlign: 'center',
      fontSize: 4,
      marginBottom: '1.25rem',
    },
    menuItemsContainer: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      marginBottom: 0,
      padding: '0rem 0.5rem',
    },

    // ?================================
    // ?========  Menu Items ===========
    // ?================================

    menuItemContainer: {
      display: 'flex',
      overflow: 'hidden',
      // padding: ['0 0.5rem', '', '0 1rem'],
      marginBottom: '1rem',
      margin: ['', '0.5rem','','2rem'],
      width: [
        '100%',
        '',
        'calc(50% - (0.5rem * 2))',
        '',
        'calc(33.33% - (2rem * 2))',
      ],
      minHeight: '300px',
      maxHeight:'500px',
    },
    menuItemContainerImgActive: {
      variant: `${variantName}.menuItemContainer`,
    },

    menuItemInnerContainer: {
      marginBottom: '1rem',
      height: '100%',
      width: '100%',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'stretch',
      flexDirection: ['column', 'row'],
      position: 'relative',
    },

    // ?================================
    // ?======  Menu Item Image  =======
    // ?================================

    menuItemImageSliderContainer: {
      width: ['100%', '100%'],
      height: '100%',
      '.slick-slider, .slick-list, .slick-track, .slick-slide, .slick-slide > div': {
        height: 'inherit',
      },

      '.slick-dots': {
        display: 'none !important',
      },
      '.slick-arrow': {
        right: 'unset',
        top: 'unset',
        left: '0rem',
        bottom: '0rem',
        zIndex: '100',
        width: '20px',
        height: '20px',
        color: 'secondary',
      },
      '.slick-prev': {
        ':hover': {
          opacity: '0.8',
          color: 'secondary',
        },
      },
      '.slick-next': {
        left: '1rem',
        ':hover': {
          opacity: '0.8',
          color: 'secondary',
        },
      },
    },

    menuItemImageContainer: {
      borderRadius: '10px 10px 0 0',
      position: 'relative',
      cursor: 'pointer',
      maxWidth: ['', '100%'],
      filter: 'brightness(0.75)',
      height: 'inherit',
    },
    itemImageFill: {},
    menuItemImg: {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      // padding: '0 0.75rem 0 0',
      cursor: 'pointer',
    },
    menuItemImgDefault: {
      position: 'unset',
      objectFit: 'contain',
      display: 'none',
    },

    // ?================================
    // ?=====  Menu Item Content  ======
    // ?================================

    menuItemContentContainer: {
      position: 'absolute',
      height: '100%',
      flexDirection: 'column',
      flexGrow: 2,
      flexBasis: 2,
      width: '100%',
      padding: '1rem',
      color: 'light',
    },
    menuItemName: {
      variant: 'customVariants.title',
      fontSize: ['1rem', '1.1rem', '', '2.5rem'],
      color: 'white',
      fontWeight: 'bolder',
      margin: '0px',
      marginBottom: '1rem',
      paddingRight: '8px',
      border: 'none',
      // borderBottom: '3px solid',
      // borderColor: 'white',
      paddingBottom: '0.5rem',
    },
    menuItemDescription: {
      fontSize: '0.9rem',
      whiteSpace: 'break-spaces',
      display: 'none',
    },

    menuItemButton: {
      margin: 'auto auto 0rem 0rem',
      backgroundColor: 'secondary',
      cursor: 'pointer',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '0rem',
      left: '0rem',
      opacity: '0',
    },

    // ?================================
    // ?======  Menu Items Price =======
    // ?================================

    menuPriceContainer: {
      flexGrow: '1',
      fontSize: ['1rem', '1.1rem'],
      justifyContent: 'flex-end',
    },

    menuItemPrice: {
      margin: '0px',
      textAlign: 'right',
    },
    itemVariantsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: '0.75rem',
      fontSize: ['0.7rem', '0.8rem', '0.9rem'],
    },

    variantsContainer: {
      display: 'flex',
      alignItems: 'center',
    },

    labelTitle: {
      display: 'flex',
    },
    variantContainer: {
      padding: ['0.5rem 1rem 0rem 0rem', '', '0.5rem 1rem 0.5rem 0rem'],
      width: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      textAlign: 'left',
    },

    menuItemPriceLabel: {
      margin: '0px',
      // paddingRight: '8px',
      textTransform: 'capitalize',
      fontWeight: 'bold',
    },
    menuItemPriceVariants: {},

    // ?================================
    // ?======= Section Dropdown =======
    // ?================================

    dropdownContainer: {
      justifyContent: 'center',
      position: 'relative',
    },

    menuDropdownBtn: {
      background: 'primary',
      border: '2px solid',
      textTransform: 'uppercase',
      fontWeight: '600',
      fontSize: '1.25rem',
      display: 'inline-flex',
      alignItems: 'center',
      borderRadius: '4px',
      padding: '0.75rem 1.75rem',
    },

    menuSectionsDropDownContainer: {
      backgroundColor: 'background',
      boxShadow: '2px 2px 8px lightgrey',
      borderRadius: '10px',
      position: 'absolute',
      top: '100%',
      zIndex: '100',
      margin: '0 2.5%',
      display: 'flex',
      justifyContent: 'center',
      div: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '1rem',
        borderRadius: '4px',
        margin: 'auto',
        justifyContent: 'center',
      },
    },

    dropdownSection: {
      width: ['calc(50% - (0.5rem * 2))', 'calc(33.33% - (0.5rem * 2))'],
      margin: '0.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'stretch',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '1.25rem',
      span: {
        '&:hover': {
          textDcoration: 'underline',
        },
      },
    },

    // ?================================
    // ?======== MENU ITEMS PAGE =======
    // ?================================

    menuItemPage: {
      display: ['', '', 'flex'],
      flexWrap: ['', '', 'wrap'],
      maxWidth: '1200px',
      margin: '0 auto',
    },
    MenuItemPageBackButton: {
      marginLeft: '1rem',
    },
    menuItemPageItemName: {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5', '5rem', '5rem'],
      padding: '1rem',
      marginTop: '1rem',
      width: '100%',
    },
    menuItemPageImagesContainer: {
      flexWrap: 'wrap',
      alignItems: 'stretch',
      margin: '1rem 0rem',
      justifyContent: 'center',
      display: 'flex',
    },
    menuItemPageImageContainer: {
      objectFit: 'cover',
      padding: 1,
      width: ['50%', '', '33.33%', '25%', '', '20%'],
      maxWidth: '600px',
      flexGrow: '1',
      ':hover': {
        cursor: 'zoom-in',
      },
      img: {
        height: '300px',
      },
    },
    menuItemPageContent: {
      // width: ['', '', '50%'],
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: ['1rem', '', '1rem'],
      '.variantsContainer': {
        padding: '1rem 2rem 1rem 0rem',
        borderTop: '2px solid',
        borderColor: 'secondary',
      },
    },
    menuItemPageBackButtonContainer: {
      width: '100%',
    },
    menuItemPageImageCaption: {}, // light box

    menuItemPageDescription: {
      maxWidth: '767px',
    },
  },
};
